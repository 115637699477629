import React from 'react';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import TextWithChecklistImg from '@beelineloans/cx-library/src/components/layout/panels/TextWithChecklistImg';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import TitleTemplate from '../Template';

const BeelineTitle = () => (
  <TitleTemplate
    headline="Get title insurance from&nbsp;your&nbsp;sofa."
    subHeadline="Introducing Beeline Title — the simplest way&nbsp;to&nbsp;get title insurance when you’re working&nbsp;with Beeline Loans."
  >
    <SEO
      path={CONST.LINKS.TITLE.HOME}
      title="Get title insurance from your sofa."
      description="We are mortgage lending experts that ship sophisticated lending products faster than our competitors."
    />
    <TextWithChecklistImg
      heading="Say hello to a faster, smoother title experience."
    >
      <>
        The last thing you want is a hiccup at title time.<br /><br />
        We get it — our team have worked at the most reputable title companies in the US,&nbsp;
        and built innovative startups that revolutionized the title insurance space.<br /><br />
        So we’ve got the know-how to ship sophisticated lending products faster than most. We ensure loans close on-time and&nbsp;
        without those frustrating avoidable hold-ups.
      </>
    </TextWithChecklistImg>
  </TitleTemplate>
);

export default BeelineTitle;
